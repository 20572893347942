<template>
  <div class="app-modal__body p-5 pb-0">
    <div class="timeline-items__right w-100 rounded-sm p-4">
      <el-collapse accordion class="collapseno-border">
        <el-collapse-item name="1">
          <template slot="title">
            <span>{{$t('message.user_email')}}</span>
            <i class="ml-2 el-icon-message"></i>
          </template>
          <div v-for="(value, index) in emails" :key="index">
            <el-row :gutter="20" v-loading="value.loading">
              
              <el-col :span="8">
                <div class="app-form__group mb-4">
                  <span class="input--label d-block mb-2">{{$t('message.email')}} </span>
                  <el-input
                    :placeholder="$t('message.user_email')"
                    v-model="value.email"
                    size="medium"
                    :disabled="value.connected"
                    type="email"
                  ></el-input>
                </div>
              </el-col>

              <el-col :span="8">
                <div class="app-form__group mb-4">
                  <span class="input--label d-block mb-2">{{$t('message.email_password')}}</span>
                  <el-input
                    :placeholder="$t('message.email_password')"
                    v-model="value.mail_password"
                    size="medium"
                    :disabled="value.connected"
                    type="password"
                    show-password
                  ></el-input>
                </div>
              </el-col>
              <!-- end col -->
              <el-col :span="8">
                <div class="app-form__group mb-4">
                  <span class="input--label d-block mb-2">{{$t('message.smtp_server')}}  </span>
                  <el-input
                    :placeholder="$t('message.smtp_server')"
                    v-model="value.smtp_server"
                    size="medium"
                    :disabled="value.connected"
                  ></el-input>
                </div>
              </el-col>
              <!-- end col -->
              <el-col :span="8">
                <div class="app-form__group mb-4">
                  <span class="input--label d-block mb-2">{{$t('message.smtp_port')}}</span>
                  <el-input
                    :placeholder="$t('message.smtp_port')"
                    v-model="value.smtp_port"
                    size="medium"
                    type="number"
                    :disabled="value.connected"
                  ></el-input>
                </div>
              </el-col>
              <!-- end col -->
              
              <el-col :span="8">
                <div class="app-form__group mb-4">
                  <span class="input--label d-block mb-2">
                    {{$t('message.imap_server')}}
                  </span>
                  <el-input
                    :placeholder="$t('message.imap_server')"
                    v-model="value.imap_server"
                    size="medium"
                    :disabled="value.connected"
                  ></el-input>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="app-form__group mb-4">
                  <span class="input--label d-block mb-2">
                    {{$t('message.imap_server')}}
                  </span>
                  <el-input
                    :placeholder="$t('message.imap_port')"
                    v-model="value.imap_port"
                    size="medium"
                    type="number"
                    :disabled="value.connected"
                  ></el-input>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="app-form__group mb-4">
                  <span class="input--label d-block mb-2">
                    {{$t('message.pop3_server')}}
                  </span>
                  <el-input
                    :placeholder="$t('message.pop3_server')"
                    v-model="value.pop3_server"
                    size="medium"
                    :disabled="value.connected"
                  ></el-input>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="app-form__group mb-4">
                  <span class="input--label d-block mb-2">
                   {{ $t('message.pop3_server')}}
                  </span>
                  <el-input
                    :placeholder="$t('message.pop3_server')"
                    v-model="value.pop3_port"
                    size="medium"
                    type="number"
                    :disabled="value.connected"
                  ></el-input>
                </div>
              </el-col>
              <el-col :span="8" class="right-block">
                <el-button
                  @click="checkConnection(value, index)"
                  type="primary"
                  size="medium"
                  plain
                  :disabled="value.connected"
                >
                    {{ value.connected ? 'почта подключена': 'Проверьте подключение'}}
                </el-button>
                <el-button
                  v-if="index+1 === emails.length"
                  @click="addEmail()"
                  type="primary"
                  size="medium"
                  plain
                >
                  {{ $t('message.add_email')}}
                </el-button>
                <el-button
                  v-else
                  @click="deleteForm(value, index)"
                  type="danger"
                  size="medium"
                  plain
                >
                  {{ $t('message.delete')}}
                </el-button>
              </el-col>

              <!-- end col -->
            </el-row>
            <hr>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
  <!-- end app-modal__body -->
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";

export default {
  name: "contact-account",
  mixins: [],
  props: {
    form:{
      default: Object
    }
  },
  data() {
    return {
      emails: [],
      loadCheckingConection: false,
    };
  },
  created: function () {
    let value = {
      id: '',
      email: '',
      loading: false,
      mail_password: '',
      smtp_server: '',
      smtp_port: '',
      imap_server: '',
      imap_port: '',
      pop3_server: '',
      pop3_port: '',
      connected: false,
    };
    this.emails.push(value);
  },
  watch: {
      emails: {
        handler: async function(newVal, oldVal) {
          this.form.user_emails = this.emails;
        },
        deep: true,
        immediate: true
      },
  },
  methods: {
    ...mapActions({
      checkEmailConnection: "users/checkEmailConnection",
      deleteUserEmail: "users/deleteUserEmail",
    }),
    checkConnection(email, index){
      if(email.email && email.mail_password && email.smtp_server && email.smtp_port && email.imap_server && email.imap_port){
        email.loading = true;
        this.checkEmailConnection(email)
          .then(res => {
            if(res.data.result.success){
              this.emails[index].connected = true
              this.$alert(res);
            }else{
              this.$message({
                type: "warning",
                message: res.data.result.message
              });
            }
            email.loading = false;
          })
          .catch(err => {
            email.loading = false;
            this.$message({
              type: "warning",
              message: this.$t('message.Problem checking connection, please try again')
            });
          })
      }else{
        this.$message({
            type: "warning",
            message: this.$t('message.To test the connection, first enter your IMAP and SMTP details.')
          });
      }
    },
    addEmail(){
      if(this.emails.length > 0 && this.emails.length < 5 && this.emails[this.emails.length-1].email.includes('@') && this.emails[this.emails.length-1].email.includes('.')){
        let value = {
          id: '',
          email: '',
          loading: false,
          mail_password: '',
          smtp_server: '',
          smtp_port: null,
          imap_server: '',
          imap_port: null,
          pop3_server: '',
          pop3_port: null,
          connected: false,
        };
        
        this.emails.push(value);
      }else if(!this.emails[this.emails.length-1].email){
          this.$message({
            type: "warning",
            message: this.$t("message.Enter your email address")
          });
      }else if(!this.emails[this.emails.length-1].email.includes('@') || !this.emails[this.emails.length-1].email.includes('.')){
          this.$message({
            type: "warning",
            message: this.$t("message.Please enter your email correctly!")
          });
      }else if(this.emails.length === 5){
          this.$message({
            type: "warning",
            message: this.$t("message.You cannot add more than 5 email addresses!")
          });
      }
      
    },
    deleteForm(email, index) {

      this.$confirm(
          this.$t('message.do_you_really_want_to_do_this'),
          this.$t('message.warning'), {
            confirmButtonText: this.$t('message.yes'),
            cancelButtonText: this.$t('message.no'),
            type: "warning"
          }
        )
        .then(() => {
          
          if (this.emails.length === 1) {
            this.$message({
              type: "warning",
              message: this.$t("message.unable_to_delete_last_form"),
            });
          } else {
            if(email.id){
              this.deleteUserEmail(email.id)
                .then(res => {
                  if(res.status === 200){
                    this.emails.splice(index, 1);
                    this.$alert(res);
                  }else{
                    this.$message({
                      type: "warning",
                      message: this.$t('message.operation_canceled')
                    });
                  }
                }).catch(err => {
                  console.log(err, 'errror');
                });
            }else{
              this.emails.splice(index, 1);
            }
          }

        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: this.$t('message.operation_canceled')
          });
        });
    },
  }
};
</script>

